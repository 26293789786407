import { Form, Link } from '@remix-run/react';
import { Price } from '~/components/products/Price';
import { ActiveOrderQuery, CurrencyCode } from '~/generated/graphql';
import { replaceFileName } from '~/generated/tds/bp-utils';
import { hasQuantityRelevance, productHasPrice } from '~/utils/ecom-helpers';

export function CartContents({
  orderLines,
  currencyCode,
  editable = true,
  adjustOrderLine,
  removeItem,
}: {
  orderLines: any[];
  currencyCode: CurrencyCode;
  editable: boolean;
  adjustOrderLine?: (lineId: string, quantity: number) => void;
  removeItem?: (lineId: string) => void;
}) {
  const isEditable = editable !== false;


  return (
    <div className="flow-root">
      <ul role="list" className="-my-6 divide-y divide-gray-200">
        {(orderLines ?? []).map((line) => (
          <li key={line.id} className="py-6 flex">
            <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
              <img
                src={line.featuredAsset?.preview}
                alt={line.productVariant.name}
                className="w-full h-full object-center object-cover"
              />
            </div>

            <div className="ml-4 flex-1 flex flex-col">
              <div>
                <div className="flex justify-between text-base font-medium text-gray-900">
                  <h3>
                    <Link to={`/apps/${line.slug}`}> 
                      {line.productVariant.name}
                    </Link>
                  </h3>
                  {productHasPrice(line.productVariant) ?? (<p className="ml-4">
                    <Price
                      priceWithTax={line.linePriceWithTax}
                      currencyCode={currencyCode}
                    ></Price>
                  </p>)}
                </div>
                { line.options?.length ? <div className="flex justify-between text-base font-medium text-gray-900">
                  <div className="mt-2">
                    Options
                    <div>
                      {line.options.map((o: any) => 
                        <div key={o.optionId}>{o.optionLabel} { o.value?.length ? (
                          
                          <ul className="option-list">
                            { o.value.map((v: any) => 
                              <li className="option-listitem" key={v.id}>{v.label}{v.sku ? ` (SKU: ${v.sku})` : ''}</li>
                            ) }
                          </ul>
                        ) : <ul className="option-list">
                              <li className="option-listitem">
                                {o.value?.label}{o.value?.sku ? ` (SKU: ${o.value.sku})` : ''}
                              </li>
                            </ul> }
                        </div>) }
                    </div>
                  </div>
                </div> : '' }
              </div>
              <div className="flex-1 flex items-center text-sm">
                {hasQuantityRelevance(line) && editable ? (
                  <Form>
                    <label htmlFor={`quantity-${line.id}`} className="mr-2">
                      Quantity
                    </label>
                    <select
                      disabled={!isEditable}
                      id={`quantity-${line.id}`}
                      name={`quantity-${line.id}`}
                      value={line.quantity}
                      onChange={(e) =>
                        adjustOrderLine &&
                        adjustOrderLine(line.id, +e.target.value)
                      }
                      className="max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                    </select>
                  </Form>
                ) : ( ''
                )}
                <div className="flex-1"></div>
                <div className="flex">
                  {isEditable && (
                    <button
                      type="button"
                      name="removeItem"
                      value={line.id}
                      className="font-medium text-secondary hover:text-primary-500"
                      onClick={() => removeItem && removeItem(line.id)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
